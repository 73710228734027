import axios, { AxiosRequestConfig, AxiosResponse, Method, AxiosError } from 'axios'
import { getAccessToken } from './index'
import awsconfig from '../aws-exports'
import { EventSourcePolyfill } from 'event-source-polyfill';
import jwtDecode from 'jwt-decode';



export const api = async <T, R = AxiosResponse<T>> (config: AxiosRequestConfig) : Promise<R> => {
  const accessToken = await getAccessToken()
  return axios.create({
    baseURL: `${awsconfig.yoor_app_api_url_base}/api`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    withCredentials: true
  }).request(config)
}

/**
 *
 * @param method HTTPメソッド
 * @param path エンドポイント
 * @param data リクエストボディ
 * @param headers 追加ヘッダ
 * @returns
 * 
 * @deprecated
 */
export const requestV1 = async <T,>(
  method: Method,
  path: string,
  data?: Object,
  headers?: Object
): Promise<AxiosResponse<T>> => {
  const accessToken = await getAccessToken()
  return await axios.request({
    method,
    url: `${awsconfig.yoor_app_api_url_base}/api${path}`,
    data,
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Version': 'v1',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      ...headers
    },
    withCredentials: true
  })
}
export const isAxiosError = (error: any): error is AxiosError => {
  return !!error.isAxiosError
}
/**
 * @param method HTTPメソッド
 * @param path エンドポイント
 * @param data リクエストボディ
 * @param headers 追加ヘッダ
 * @param version バージョニング指定(v1, v2 etc...)
 * @returns
 */
export const requestAny = async <T,>(
  method: Method,
  path: string,
  version: string,
  data?: Object,
  headers?: Object
): Promise<AxiosResponse<T>> => {
  const accessToken = await getAccessToken()
  return await axios.request({
    method,
    url: `${awsconfig.yoor_app_api_url_base}/api/${version}${path}`,
    data,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      ...headers
    },
    withCredentials: true
  })
}

/**
 * @param method HTTPメソッド
 * @param path エンドポイント
 * @param data リクエストボディ
 * @param headers 追加ヘッダ
 * @param version バージョニング指定(v1, v2 etc...)
 * @returns
 */
export const requestV2 = async <T,>(request: {
  method: Method
  path: string
  params?: Object
  data?: Object
  headers?: Object
}): Promise<AxiosResponse<T>> => {
  const accessToken = await getAccessToken()
  return await axios.request({
    method: request.method,
    url: `${awsconfig.yoor_app_api_url_base}/api/v2${request.path}`,
    params: request.params,
    data: request.data,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      ...request.headers
    },
    withCredentials: true
  })
}

/**
 *
 * @param method
 * @param path エンドポイント
 * @param params
 * @param headers 追加ヘッダ
 * @returns
 */
export const requestHacoCms = async <T,>(
  method: Method,
  path: string,
  params?: Object,
  headers?: Object
): Promise<AxiosResponse<T>> => {
  return await axios.request({
    method,
    url: `https://${process.env.REACT_APP_HACOCMS_SUBJECT_DOMAIN}.hacocms.com/api/v1${path}`,
    params,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_HACOCMS_SERECT}`,
      ...headers
    }
  })
}

export const streamV1 = async (path: string): Promise<EventSourcePolyfill> => {
  return new EventSourcePolyfill(`${awsconfig.yoor_app_api_url_base}/api${path}`, {
    headers: {
      'X-Api-Version': 'v1',
      'Authorization': `Bearer ${await getAccessToken()}`,
    },
    withCredentials: true
  })
}
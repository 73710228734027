// node modules
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// Material-UI
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Dialog from '@material-ui/core/Dialog'
import { useAuth } from 'contexts/AuthContext'
import CancelIcon from '@material-ui/icons/Cancel'
import {
  setStaffStateKey,
  removeStaffStateKey,
  getStaffStateKey
} from 'apis/invitation'
// redux
import {
  getHasDoor,
  getScreenName,
  getUserName,
  getUserIcon,
  getIsSignin,
  getCurrentDoorName
} from 'modules/me'
import alertModules from 'modules/alert'
import { RootState } from 'store'
import { createMeDoor } from 'apis/me/door'
import meModules from 'modules/me'

// resources
import { isAxiosError } from 'apis/base'
import { getDoorsAsStaff, DoorAsStaff } from 'apis/staff'
import DummyHeader from '../../images/dummy_header.jpg'

export default function UserMenu(props: {
  open: boolean
  toggleDrawer: Function
}) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const baseSalonPath = '/door/'
  const baseMemberPath = '/management/door/members'
  const baseConfigPath = '/management/door/edit'
  const baseDashboardPath = '/management/sales'

  const currentDoorName = useSelector((state: RootState) =>
    getCurrentDoorName(state)
  )
  const [currentMemberPath, setCurrentMemberPath] = useState<string>('')
  const [currentConfigPath, setCurrentConfigPath] = useState<string>('')
  const [currentSalonPath, setCurrentSalonPath] = useState<string>('')
  const [currentDashboardPath, setCurrentDashboardPath] = useState<string>('')

  const [showSwitch, setShowSwitch] = useState(false)
  const [showStaffDoors, setShowStaffDoors] = useState(false)
  const [staffDoors, setStaffDoors] = useState<Array<DoorAsStaff> | null>(null)
  const [myDoor, setMyDoor] = useState<DoorAsStaff | null>(null)
  const [currentDoor, setCurrentDoor] = useState<string | null>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [openConfirmOpenDoor, setOpenConfirmOpenDoor] = useState(false)

  const hasDoor = useSelector((state: RootState) => getHasDoor(state))
  const screenName = useSelector((state: RootState) => getScreenName(state))
  const userName = useSelector((state: RootState) => getUserName(state))
  const userIcon = useSelector((state: RootState) => getUserIcon(state))
  const isSignin = useSelector((state: RootState) => getIsSignin(state))

  const linkColor = { color: '#11b5f5' }
  const { signOut } = useAuth()

  const handleClickOpen = () => {
    setDialogOpen(true)
  }
  const handleClose = () => {
    setDialogOpen(false)
  }

  const toOpenDoor = () => {
    setOpenConfirmOpenDoor(true)
  }

  const handleOpenDoor = async () => {
    removeStaffStateKey()
    try {
      await createMeDoor({
        door: {
          title: '',
          description: '',
          keyword_ids: []
        }
      })
    } catch (err) {
      if (isAxiosError(err) && err.response?.status === 409) {
        // ドア作成済みはリロード
        window.location.href = `/door/${screenName}`
        return
      }
      dispatch(
        alertModules.actions.setAlert({
          text: 'エラーが発生しました。サロンの開設ができませんでした',
          severity: 'error'
        })
      )
      return
    }

    window.gtag('event', 'click', {
      event_category: 'Create Door Form Button',
      event_label: screenName
    })

    window.location.href = `/door/${screenName}`
  }

  const initStaff = async () => {
    try {
      const res = await getDoorsAsStaff()
      const staffState = getStaffStateKey()
      if (res.data.door) setMyDoor(res.data.door)
      if (staffState) {
        const targetDoor = res.data.doors?.find(
          (d) => d.twitter_screen_name === staffState
        )
        if (!targetDoor) {
          removeStaffStateKey()
          setCurrentMyDoorConfig(res.data.door)
          return
        }
        setCurrentDoor(staffState)
        setCurrentOtherDoorConfig(targetDoor)
      } else {
        if (res.data.door) {
          setCurrentMyDoorConfig(res.data.door)
        } else if (res.data.doors?.length === 1) {
          const targetDoor = res.data.doors[0]
          setStaffStateKey(targetDoor.twitter_screen_name)
          setCurrentDoor(targetDoor.twitter_screen_name)
          setCurrentOtherDoorConfig(targetDoor)
        }
      }
      setStaffDoors(res.data?.doors)
      if (res.data.doors?.length > 1 || (res.data.doors && res.data.door))
        setShowSwitch(true)
    } catch (error) {
      console.error(error)
    }
  }
  const setCurrentMyDoorConfig = (door: DoorAsStaff | undefined) => {
    if (door === undefined) return
    dispatch(meModules.actions.setCurrentDoorName(door.title))
    setCurrentDoor('')
    setCurrentSalonPath(`${baseSalonPath}${door.twitter_screen_name}`)
    setCurrentMemberPath(baseMemberPath)
    setCurrentConfigPath(baseConfigPath)
    setCurrentDashboardPath(baseDashboardPath)
  }
  const setCurrentOtherDoorConfig = (door: DoorAsStaff) => {
    dispatch(meModules.actions.setCurrentDoorName(door.title))
    setCurrentSalonPath(`${baseSalonPath}${door.twitter_screen_name}`)
    setCurrentMemberPath(
      `${baseSalonPath}${door.twitter_screen_name}${baseMemberPath}`
    )
    setCurrentConfigPath(
      `${baseSalonPath}${door.twitter_screen_name}${baseConfigPath}`
    )
    door.position === 'admin_owner'
      ? setCurrentDashboardPath(
          `${baseSalonPath}${door.twitter_screen_name}${baseDashboardPath}`
        )
      : setCurrentDashboardPath('')
  }
  const switchDoor = (targetDoor?: DoorAsStaff) => {
    if (targetDoor === undefined) {
      if (myDoor === null) return
      setCurrentMyDoorConfig(myDoor)
      removeStaffStateKey()
      window.location.href = `${baseSalonPath}${myDoor?.twitter_screen_name}`
    } else {
      setStaffStateKey(targetDoor.twitter_screen_name)
      setCurrentDoor(targetDoor.twitter_screen_name)
      setCurrentOtherDoorConfig(targetDoor)
      window.location.href = `${baseSalonPath}${targetDoor.twitter_screen_name}`
    }
    toggleClose()
  }
  const toggleClose = () => {
    props.toggleDrawer(false)()
    setShowStaffDoors(false)
  }
  useEffect(() => {
    initStaff()
  }, [])

  function PersonalMenus(accoutConfigStyle?: {}) {
    return (
      <>
        {/* アカウント設定 */}
        <li
          className={location.pathname === `/account/settings` ? 'active' : ''}
          style={accoutConfigStyle}
        >
          <div
            onClick={() => {
              history.push('/account/settings')
            }}
          >
            アカウント設定
          </div>
        </li>
        {/* 参加中のサロン */}
        <li
          className={
            location.pathname === `/management/door/subscribing` ? 'active' : ''
          }
        >
          <div
            onClick={() => {
              history.push('/management/door/subscribing')
            }}
          >
            参加中のサロン
          </div>
        </li>
        {/* スクラッチ獲得履歴 */}
        <li
          className={
            location.pathname.indexOf(`/account/scratches`) === 0 ? 'active' : ''
          }
        >
          <Link to={`/account/scratches`}>スクラッチ獲得履歴</Link>
        </li>
      </>
    )
  }

  function SalonMenus(showSalonMenu: boolean, membersStyle?: {}) {
    return (
      <>
        {/* サロンページ */}
        {showSalonMenu && (
          <>
            <li
              className={location.pathname === currentSalonPath ? 'active' : ''}
            >
              <div
                onClick={() => {
                  toggleClose()
                  history.push(currentSalonPath)
                }}
              >
                サロンページ
              </div>
            </li>
            {/* ダッシュボード */}
            {currentDashboardPath !== '' && (
              <li
                className={
                  location.pathname === currentDashboardPath ? 'active' : ''
                }
              >
                <div
                  onClick={() => {
                    history.push(currentDashboardPath)
                  }}
                >
                  ダッシュボード
                </div>
              </li>
            )}
            {/* サロン設定 */}
            <li
              className={
                location.pathname.indexOf("/management/door/edit") === 0 ? 'active' : ''
              }
            >
              <Link to="/management/door/edit">サロン設定</Link>
            </li>
            {/* スクラッチ管理 */}
            <li
              className={
                (
                  location.pathname.indexOf("/management/scratches") === 0 ||
                  location.pathname.indexOf("/management/scratch_items") === 0
                ) ? 'active' : ''
              }
            >
              <Link to="/management/scratches">スクラッチ管理</Link>
            </li>
            {/* メンバー管理 */}
            <li
              className={
                location.pathname === currentMemberPath ? 'active' : ''
              }
              style={showSwitch ? {} : membersStyle}
            >
              <div
                onClick={() => {
                  history.push(currentMemberPath)
                }}
              >
                メンバー管理
              </div>
            </li>
          </>
        )}
        {/* サロン切り替え */}
        {showSwitch && (
          <li style={membersStyle}>
            <div onClick={handleClickOpen}>サロン切り替え</div>
            {staffDoors && (
              <Dialog
                open={dialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="staff_doors_wrapper"
              >
                <div className="staff_doors">
                  <h2 className="staff_doors_title">サロン切り替え</h2>
                  <CancelIcon onClick={() => handleClose()} className="close" />
                  <ul>
                    {myDoor && (
                      <li
                        className={currentDoor === '' ? 'selected' : ''}
                        onClick={() => {
                          switchDoor()
                          handleClose()
                        }}
                      >
                        <div className="image">
                          <img
                            src={myDoor.header_image_url}
                            onError={(e) => {
                              let target = e.target as HTMLImageElement
                              target.src = DummyHeader
                            }}
                            alt={myDoor.title}
                          />
                        </div>
                        <p className="title">
                          {myDoor.title !== ''
                            ? myDoor.title
                            : 'サロン名未設定'}
                        </p>
                      </li>
                    )}
                    {staffDoors.map((door, i) => {
                      return (
                        <li
                          key={i}
                          className={
                            currentDoor === door.twitter_screen_name
                              ? 'selected'
                              : ''
                          }
                          onClick={() => {
                            switchDoor(door)
                            handleClose()
                          }}
                        >
                          <div className="image">
                            <img
                              src={door.header_image_url}
                              onError={(e) => {
                                let target = e.target as HTMLImageElement
                                target.src = DummyHeader
                              }}
                              alt={door.title}
                            />
                          </div>
                          <p className="title">
                            {door.title !== '' ? door.title : 'サロン名未設定'}
                          </p>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </Dialog>
            )}
          </li>
        )}
      </>
    )
  }

  return (
    <>
      {isSignin ? (
        <SwipeableDrawer
          anchor="right"
          open={props.open}
          onClose={toggleClose}
          onOpen={props.toggleDrawer(true)}
        >
          <div
            className="modal-drawer"
            id="modal-nav"
            role="list"
            onKeyDown={props.toggleDrawer(false)}
          >
            <Link to={`/door/${screenName}`} className="modal-user -userMenu">
              <div
                className="icon"
                style={{
                  backgroundImage: `url(${userIcon})`,
                  backgroundSize: 'cover',
                  backgroundPosition: '50% 50%'
                }}
              ></div>
              <div className="username">{userName}</div>
            </Link>
            {currentDoorName !== '' && (
              <p className="currentDoorName">{currentDoorName}</p>
            )}
            <ul className="modal-list">
              {(() => {
                if (hasDoor) {
                  return (
                    <>
                      {SalonMenus(true, {
                        borderBottom: '1px solid #D7DCDF',
                        marginBottom: '16px',
                        paddingBottom: '16px'
                      })}
                      {PersonalMenus()}
                    </>
                  )
                } else {
                  return (
                    <>
                      {SalonMenus(currentDoor !== null, {
                        borderBottom: '1px solid #D7DCDF',
                        marginBottom: '16px',
                        paddingBottom: '16px',
                        position: 'relative'
                      })}
                      <li
                        className={
                          location.pathname === `/door/${screenName}`
                            ? 'active'
                            : ''
                        }
                      >
                        <div
                          onClick={() => {
                            toggleClose()
                            history.push(`/door/${screenName}`)
                          }}
                        >
                          マイページ
                        </div>
                      </li>
                      {PersonalMenus()}
                      {/* ユーザー情報 */}
                      <li
                        className={
                          location.pathname === `/account/door/edit`
                            ? 'active'
                            : ''
                        }
                      >
                        <div
                          onClick={() => {
                            history.push('/account/door/edit')
                          }}
                        >
                          ユーザー情報
                        </div>
                      </li>
                    </>
                  )
                }
              })()}
              <li
                style={{
                  borderTop: '1px solid #D7DCDF',
                  marginTop: '16px',
                  paddingTop: '16px'
                }}
              >
                <a href="/support">サポートサービス</a>
              </li>
              {!hasDoor && (
                <li>
                  <div
                    onClick={toOpenDoor}
                  >
                    サロンを開設する
                  </div>
                </li>
              )}
              <li>
                <div
                  onClick={async () => {
                    await signOut()
                    history.push('/signin')
                  }}
                >
                  ログアウト
                </div>
              </li>
            </ul>
          </div>
        </SwipeableDrawer>
      ) : (
        <></>
      )}
      <Dialog
        open={openConfirmOpenDoor}
        onClose={() => setOpenConfirmOpenDoor(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <section className="modal" id="modal-door">
          <div className="modal-contents" style={{paddingBottom: '40px'}}>
            <div style={{textAlign: 'center', fontWeight: 'bold',margin:'32px 0 40px'}}>
              サロンを開設しますか？
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <button onClick={() => setOpenConfirmOpenDoor(false)} className="textLink" style={{color: '#999', margin: '0 32px'}}>キャンセル</button>
              <button onClick={handleOpenDoor} className="btn" style={{width: '160px'}}>開設する</button>
            </div>
          </div>
        </section>
      </Dialog>

    </>
  )
}

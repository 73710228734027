import Amplify from 'aws-amplify'
import React, { Suspense, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import './styles/common.scss'

import makeStyles from '@material-ui/core/styles/makeStyles'
import awsconfig from './aws-exports'
import CognitoAuthProvider from './contexts/AuthContext'
import AuthenticatedRoute from './utils/AuthenticatedRoute'
import YoorDoor1 from './images/yoor_door_1.svg'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import ScrollToTop from './contexts/ScrollToTop'
import Snackbar from './components/common/Snackbar'
import Loading from 'components/common/Loading'
import LoadingAnimation from 'components/common/LoadingAnimation'
import AccountSettings from './containers/account/settings/AccountSettings'
import NotFound from 'components/common/NotFound'

const SamplePost = React.lazy(() => import('containers/door/SamplePost'))

const TopPageView = React.lazy(
  () => import('./components/landingpage/TopPageView')
)

const MyPage = React.lazy(
  () => import('containers/MyPage')
)

const OwnerMore = React.lazy(() => import('containers/OwnerMore'))

// import PickupList from './components/landingpage/Pickup'
const SearchDoorList = React.lazy(
  () => import('./components/landingpage/Search')
)

const FreeDoorList = React.lazy(
  () => import('./components/common/FreeDoorList')
)

const KeywordDoorList = React.lazy(
  () => import('./components/common/KeywordDoorList')
)

const Terms = React.lazy(() => import('./containers/terms/Terms'))

const Privacy = React.lazy(() => import('./containers/privacy/Privacy'))

const Law = React.lazy(() => import('./containers/law/Law'))

const Guideline = React.lazy(() => import('./containers/guideline/Guideline'))

const Faq = React.lazy(() => import('./containers/faq/Faq'))

const FaqDetail = React.lazy(() => import('./components/faq/faqDetail'))

const FaqSearch = React.lazy(() => import('./components/faq/search'))

const Beginner = React.lazy(() => import('./containers/beginner/Beginner'))

const Opendoor = React.lazy(() => import('./containers/opendoor/Opendoor'))

const Keyword = React.lazy(() => import('./containers/keyword/Keyword'))

const NewCreditcard = React.lazy(
  () => import('./containers/account/creditcard/new/NewCreditcard')
)

const EditDoor = React.lazy(
  () => import('./containers/account/door/edit/EditDoor')
)

const EmailSettingsConfirm = React.lazy(
  () =>
    import('./containers/account/settings/email/confirm/EmailSettingsConfirm')
)

const EmailSettings = React.lazy(
  () => import('./containers/account/settings/email/EmailSettings')
)

const ForgetPassword = React.lazy(
  () => import('./containers/forgetpassword/ForgetPassword')
)

const ForgetPasswordConfirm = React.lazy(
  () => import('./containers/forgetpassword/ForgetPasswordConfirm')
)

const PasswordSettings = React.lazy(
  () => import('./containers/account/settings/password/PasswordSettings')
)

const BankCard = React.lazy(() => import('./containers/account/bank/BankCard'))

const DoorPostDetails = React.lazy(
  () => import('./containers/door/DoorPostDetails')
)

const DoorDetail = React.lazy(() => import('./containers/door/DoorDetail'))

const WithDrawal = React.lazy(
  () => import('./containers/withdrawal/WithDrawal')
)

const ManagementDoorEdit = React.lazy(
  () => import('./containers/management/door/edit/ManagementDoorEdit')
)

const DoorMembers = React.lazy(
  () => import('./containers/management/door/members/DoorMembers')
)

const ManagementSubscribingDoor = React.lazy(
  () =>
    import('./containers/management/door/subscribing/ManagementSubscribingDoor')
)

const DashBoard = React.lazy(
  () => import('./containers/management/sales/DashBoard')
)

const PaymentHistory = React.lazy(
  () => import('./containers/management/sales/history/SalesHistory')
)

const TransferRequest = React.lazy(
  () => import('./containers/management/sales/TransferRequest')
)

const Notifications = React.lazy(
  () => import('./containers/notifications/Notifications')
)

const Signin = React.lazy(() => import('./containers/signin/Signin'))

const Signup = React.lazy(() => import('./containers/signup/Signup'))

const ServerErrorMaintenance = React.lazy(
  () => import('./containers/system/Maintenance')
)

const ServerError = React.lazy(() => import('./containers/system/Error'))

const ServerErrorCrowded = React.lazy(
  () => import('./containers/system/Crowded')
)

const SignupConfirm = React.lazy(
  () => import('./containers/signupconfirm/SignupConfirm')
)

const SubscriptionPlanIndex = React.lazy(
  () => import('./containers/management/subscription_plans/index')
)

const SubscriptionPlanNew = React.lazy(
  () => import('./containers/management/subscription_plans/new')
)

const SubscriptionPlanEdit = React.lazy(
  () => import('./containers/management/subscription_plans/edit')
)

const Room = React.lazy(() => import('components/door/room/index'))

const OwnerEdit = React.lazy(() => import('./containers/management/owner/edit'))

const SelectDoorPlans = React.lazy(
  () => import('./containers/door/SelectDoorPlans')
)

const SelectDoorPlanConfirm = React.lazy(
  () => import('./containers/door/SelectDoorPlanConfirm')
)

const RedirectPost = React.lazy(() => import('components/post/Redirect'))

const Feature = React.lazy(() => import('components/Feature'))

const Channeltalk = React.lazy(() => import('components/common/Channeltalk'))

const SignupGoogle = React.lazy(
  () => import('./containers/signup/google/SignupGoogle')
)

const SignupTwitter = React.lazy(
  () => import('./containers/signup/twitter/SignupTwitter')
)

const SignupFacebook = React.lazy(
  () => import('./containers/signup/facebook/SignupFacebook')
)

const SignupLine = React.lazy(
  () => import('./containers/signup/line/SignupLine')
)

const PersonalEdit = React.lazy(
  () => import('./containers/management/personal/edit')
)

const OpenMyDoor = React.lazy(
  () => import('./containers/openmydoor/OpenMyDoor')
)

const NotificationSetting = React.lazy(
  () => import('./containers/account/settings/notification/index')
)

const BankSettings = React.lazy(
  () => import('./containers/account/settings/bank/index')
)

const EditBank = React.lazy(
  () => import('./containers/account/settings/bank/edit')
)

const NewBank = React.lazy(
  () => import('./containers/account/settings/bank/new')
)

const CreditCardSettings = React.lazy(
  () => import('./containers/account/settings/creditcard/index')
)

const YoorIdSettings = React.lazy(
  () => import('./containers/account/settings/yoorid/index')
)

const SocialAccountConnectSettings = React.lazy(
  () => import('./containers/account/settings/connect/index')
)

const InvitationEntry = React.lazy(() => import('components/Invitation'))

const InvitationConfirm = React.lazy(
  () => import('components/invitation/Confirm')
)

const InvitationExec = React.lazy(() => import('components/invitation/Exec'))

const ContainerPages = React.lazy(() => import('containers/ContainerPages'))

const Support = React.lazy(() => import('./containers/support/Support'))

const SupportDetail = React.lazy(
  () => import('./components/support/supportDetail')
)

const AiSuggestionPR = React.lazy(
  () => import('components/aisuggestionpr/AiSuggestionPR')
)

const ManagementReviews = React.lazy(
  () => import('containers/management/door/members/ManagementReviews')
)

const ScratchTop = React.lazy(
  () => import('containers/door/scratches/scratch_id')
)
const ScratchCollection = React.lazy(
  () => import('containers/scratches/collection')
)
const ScratchRecord = React.lazy(
  () => import('containers/account/scratches/index')
)

const ScratchIndex = React.lazy(
  () => import('containers/management/scratches/index')
)
const ScratchNew = React.lazy(
  () => import('containers/management/scratches/new')
)
const ScratchEdit = React.lazy(
  () => import('containers/management/scratches/edit')
)
const ScratchResult = React.lazy(
  () => import('containers/door/scratch/result')
)
const ScratchItems = React.lazy(
  () => import('containers/management/scratch_items/index')
)
const ScratchItemNew = React.lazy(
  () => import('containers/management/scratch_items/new')
)
const ScratchItemEdit = React.lazy(
  () => import('containers/management/scratch_items/edit')
)

const ScratchMemberGainHistory = React.lazy(
  () => import('containers/management/scratch_winnings/index')
)


Amplify.configure(awsconfig)

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#11b5f5'
    }
  }
})

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: {
    height: theme.spacing(6)
  },
  page: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  doorInfo: {
    marginTop: theme.spacing(1),
    justifyContent: 'flex-start'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    marginBottom: theme.spacing(1)
  }
}))

const routes = [
  { path: '/', component: TopPageView, authenticated: false },
  { path: '/mypage', component: MyPage, authenticated: true },
  { path: '/beginner', component: Beginner, authenticated: false },
  { path: '/invitation', component: InvitationEntry, authenticated: false },
  {
    path: '/invitation/confirm',
    component: InvitationConfirm,
    authenticated: true
  },
  { path: '/invitation/exec', component: InvitationExec, authenticated: true },
  {
    path: '/opendoor',
    component: () => {
      return <Redirect to="/opensalon" />
    },
    authenticated: false
  },
  { path: '/opensalon', component: Opendoor, authenticated: false },
  { path: '/keyword', component: Keyword, authenticated: false },
  { path: '/terms', component: Terms, authenticated: false },
  { path: '/privacy', component: Privacy, authenticated: false },
  { path: '/law', component: Law, authenticated: false },
  { path: '/guideline', component: Guideline, authenticated: false },
  { path: '/support', component: Support, authenticated: false },
  {
    path: '/support/:slug',
    component: () => <ContainerPages component={SupportDetail} />,
    authenticated: false
  },
  {
    path: '/faq/search',
    component: () => <ContainerPages component={FaqSearch} />,
    authenticated: false
  },
  {
    path: '/faq/:faq_id',
    component: () => <ContainerPages component={FaqDetail} />,
    authenticated: false
  },
  { path: '/faq', component: Faq, authenticated: false },
  // { path: '/pickup', component: PickupList, authenticated: false },
  {
    path: '/ownermore',
    component: () => <Redirect to="/newsalon" />,
    authenticated: false
  },
  { path: '/newsalon', component: OwnerMore, authenticated: false },
  {
    path: '/search',
    component: () => <ContainerPages component={SearchDoorList} />,
    authenticated: false
  },
  {
    path: '/free-door',
    component: () => <Redirect to="/freesalon" />,
    authenticated: false
  },
  {
    path: '/freesalon',
    component: () => <ContainerPages component={FreeDoorList} />,
    authenticated: false
  },
  // 退会画面
  { path: '/withdrawal', component: WithDrawal, authenticated: false },
  {
    path: '/door/post/:post_id',
    component: RedirectPost,
    authenticated: false
  },
  {
    path: '/door/:door_id/plans',
    component: () => <ContainerPages component={SelectDoorPlans} />,
    authenticated: false
  },
  {
    path: '/door/:door_id/samples/:id',
    component: () => <ContainerPages component={SamplePost} />,
    authenticated: false
  },
  {
    path: '/door/:door_id/plan_confirm',
    component: () => <ContainerPages component={SelectDoorPlanConfirm} />,
    authenticated: true
  },
  {
    path: '/door/:door_id',
    component: DoorDetail,
    authenticated: false
  },
  {
    path: '/door/:door_id/scratches/:scratch_id',
    component: () => <ContainerPages component={ScratchTop} />,
    authenticated: false
  },
  {
    path: '/scratches/:lottery_id/collection',
    component: () => <ContainerPages component={ScratchCollection} />,
    authenticated: false
  },
  {
    path: '/door/:door_id/scratch/result',
    component: () => <ContainerPages component={ScratchResult}></ContainerPages>,
    authenticated: false
  },
  // サロンTOPの各タブを開いた状態で遷移
  {
    path: '/door/:door_id/:tab',
    component: DoorPostDetails,
    authenticated: false
  },
  // スタッフ用メンバー管理
  {
    path: '/door/:door_id/management/door/members',
    component: () => <ContainerPages component={DoorMembers} />,
    authenticated: true
  },
  {
    path: '/door/:door_id/management/door/ai_suggestion_pr',
    component: AiSuggestionPR,
    authenticated: true
  },
  {
    path: '/door/:door_id/management/personal/edit',
    component: () => <ContainerPages component={PersonalEdit} />,
    authenticated: true
  },
  {
    path: '/door/:door_id/management/sales',
    component: DashBoard,
    authenticated: true
  },
  {
    path: '/door/:door_id/management/sales/history',
    component: PaymentHistory,
    authenticated: true
  },
  {
    path: '/door/:door_id/management/sales/transfer',
    component: TransferRequest,
    authenticated: true
  },
  {
    path: '/door/:door_id/management/sales/transfer/bank',
    component: BankCard,
    authenticated: true
  },
  {
    path: '/door/:door_id/management/reviews',
    component: () => <ContainerPages component={ManagementReviews} />,
    authenticated: true
  },
  { path: '/door/:door_id', component: DoorDetail, authenticated: false },
  {
    path: '/room/:door_id/:room_id?/:post_id?/:action?',
    component: Room,
    authenticated: false
  },
  {
    path: '/keyword/:keyword',
    component: () => <ContainerPages component={KeywordDoorList} />,
    authenticated: false
  },
  // リダイレクトするよう作りなおす
  // { path: '/door/post/:post_id', component: PostDetails, authenticated: false },

  { path: '/notifications', component: Notifications, authenticated: true },
  { path: '/management/sales', component: DashBoard, authenticated: true },
  {
    path: '/management/sales/history',
    component: PaymentHistory,
    authenticated: true
  },
  {
    path: '/management/sales/transfer',
    component: TransferRequest,
    authenticated: true
  },
  {
    path: '/management/sales/transfer/bank',
    component: BankCard,
    authenticated: true
  },
  {
    path: '/management/door/subscribing',
    component: () => <ContainerPages component={ManagementSubscribingDoor} />,
    authenticated: true
  },
  {
    path: '/management/door/members',
    component: () => <ContainerPages component={DoorMembers} />,
    authenticated: true
  },
  {
    path: '/management/door/edit',
    component: ManagementDoorEdit,
    authenticated: true
  },
  {
    path: '/management/scratches',
    component: () => <ContainerPages component={ScratchIndex}></ContainerPages>,
    authenticated: true
  },
  {
    path: '/management/scratches/new',
    component: () => <ContainerPages component={ScratchNew}></ContainerPages>,
    authenticated: true
  },
  {
    path: '/management/scratches/:scratch_id/edit',
    component: () => <ContainerPages component={ScratchEdit}></ContainerPages>,
    authenticated: true
  },
  {
    path: '/management/scratch_items',
    component: () => <ContainerPages component={ScratchItems}></ContainerPages>,
    authenticated: true
  },
  {
    path: ['/management/scratch_items/new', '/management/scratch_items/new/*'],
    component: () => <ContainerPages component={ScratchItemNew}></ContainerPages>,
    authenticated: true
  },
  {
    path: '/management/scratch_items/:scratch_item_id/edit',
    component: () => <ContainerPages component={ScratchItemEdit}></ContainerPages>,
    authenticated: true
  },
  {
    path: '/management/scratch_items/:scratch_item_id/edit/confirm',
    component: () => <ContainerPages component={ScratchItemEdit}></ContainerPages>,
    authenticated: true
  },
  {
    path: '/management/scratch_winnings',
    component: () => <ContainerPages component={ScratchMemberGainHistory}></ContainerPages>,
    authenticated: true
  },
  {
    path: '/management/door/ai_suggestion_pr',
    component: AiSuggestionPR,
    authenticated: true
  },
  {
    path: '/management/reviews',
    component: () => <ContainerPages component={ManagementReviews} />,
    authenticated: true
  },
  // オーナーサブスクプラン
  {
    path: '/management/plans',
    component: () => <ContainerPages component={SubscriptionPlanIndex} />,
    authenticated: true
  },
  // オーナーサブスクプラン
  {
    path: '/management/owner/edit',
    component: () => <ContainerPages component={OwnerEdit} />,
    authenticated: true
  },
  // オーナーサブスクプラン
  {
    path: '/management/plans/new',
    component: () => <ContainerPages component={SubscriptionPlanNew} />,
    authenticated: true
  },
  // オーナーサブスクプラン
  {
    path: '/management/plans/edit',
    component: () => <ContainerPages component={SubscriptionPlanEdit} />,
    authenticated: true
  },
  // オーナーサブスクプランのソート
  {
    path: '/management/plans/sort',
    component: () => <ContainerPages component={SubscriptionPlanIndex} />,
    authenticated: true
  },
  {
    path: '/management/personal/edit',
    component: () => <ContainerPages component={PersonalEdit} />,
    authenticated: true
  },
  { path: '/features/:name', component: Feature, authenticated: false },
  { path: '/signin', component: Signin, authenticated: false },
  { path: '/signup', component: Signup, authenticated: false },
  { path: '/account/door/edit', component: EditDoor, authenticated: true },
  {
    path: '/account/creditcard/new',
    component: NewCreditcard,
    authenticated: true
  },
  {
    path: '/account/creditcard/edit',
    component: NewCreditcard,
    authenticated: true
  },
  {
    path: '/account/settings',
    component: AccountSettings,
    authenticated: true
  },
  {
    path: '/account/settings/bank',
    component: () => <ContainerPages component={BankSettings} />,
    authenticated: true
  },
  {
    path: '/account/settings/bank/new',
    component: NewBank,
    authenticated: true
  },
  {
    path: '/account/settings/bank/edit',
    component: EditBank,
    authenticated: true
  },
  {
    path: '/account/settings/connect',
    component: SocialAccountConnectSettings,
    authenticated: true
  },
  {
    path: '/account/settings/creditcard',
    component: CreditCardSettings,
    authenticated: true
  },
  {
    path: '/account/settings/email',
    component: EmailSettings,
    authenticated: true
  },
  {
    path: '/account/settings/email/:username',
    component: EmailSettingsConfirm,
    authenticated: true
  },
  {
    path: '/account/settings/password',
    component: PasswordSettings,
    authenticated: true
  },
  {
    path: '/account/settings/notification',
    component: NotificationSetting,
    authenticated: true
  },
  {
    path: '/account/settings/yoorid',
    component: YoorIdSettings,
    authenticated: true
  },
  {
    path: '/account/scratches',
    component: () => <ContainerPages component={ScratchRecord} />,
    authenticated: true
  },
  {
    path: '/openmydoor',
    component: OpenMyDoor,
    authenticated: true
  },
  {
    path: '/signup/oauth/google',
    component: SignupGoogle,
    authenticated: false
  },
  {
    path: '/signup/oauth/facebook',
    component: SignupFacebook,
    authenticated: false
  },
  {
    path: '/signup/oauth/twitter',
    component: SignupTwitter,
    authenticated: false
  },
  {
    path: '/signup/oauth/line',
    component: SignupLine,
    authenticated: false
  },
  {
    path: '/signup/confirm',
    component: SignupConfirm,
    authenticated: false
  },
  {
    path: '/forget_password',
    component: ForgetPassword,
    authenticated: false
  },
  {
    path: '/forget_password/:emailBase64',
    component: ForgetPasswordConfirm,
    authenticated: false
  },
  {
    path: '/error',
    component: ServerError,
    authenticated: false
  },
  {
    path: '/error/crowded',
    component: ServerErrorCrowded,
    authenticated: false
  },
  {
    path: '/error/maintenance',
    component: ServerErrorMaintenance,
    authenticated: false
  }
]

export function App() {
  const trackingId = awsconfig.ga_id
  useEffect(() => {
    const { pathname } = window.location
    window.gtag('set', trackingId, { page_path: pathname })
    window.gtag('event', 'page_view')
  }, [trackingId])
  useEffect(() => {}, [])

  return (
    <CognitoAuthProvider amplifyConfig={awsconfig}>
      <ThemeProvider theme={theme}>
        <Router>
          <Suspense fallback={<LoadingAnimation />}>
            <ScrollToTop />
            <Snackbar />
            <Loading />
            <Channeltalk />
            <Switch>
              {routes.map(({ path, component, authenticated }) =>
                authenticated ? (
                  // ここもcommon cssが呼び出される
                  <AuthenticatedRoute
                    key={path.toString()}
                    exact
                    path={path}
                    component={component}
                  />
                ) : (
                  <Route
                    exact
                    key={path.toString()}
                    path={path}
                    component={component}
                  />
                )
              )}
              <Route exact component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </ThemeProvider>
    </CognitoAuthProvider>
  )
}


export default App

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import YoorDoor1 from 'images/yoor_door_1.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  page: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  }
}))

const NotFound: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <main className={classes.page}>
        <div className="container">
          <div className="contents">
            <div className="box pt0 pb0">
              <div className="text-center mt30">
                <p>アクセスしようとしたページが見つかりません</p>
              </div>
              <div className="contents-body pb0">
                <div className="text-center mt30">
                  <img src={YoorDoor1} alt="YoorDoor1" />
                </div>
                <div className="text-center mt30">
                  <p>
                    URLが間違っているか、アクセスしようとしたページは存在しておりません。
                    <br />
                    ご不便をおかけしますが、URLをご確認ください。
                  </p>
                </div>
                <div className="text-center mt30 mb30">
                  <a href="/" className="btn btn-block">
                    トップページへ
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default NotFound

import React from 'react'
import styles from 'components/landingpage/assets/TopPage.module.css'

import { Link } from 'react-router-dom'

import icon_twitter from 'images/x_logo_white.svg'
import icon_salocil from 'components/landingpage/assets/salocil_logo.svg'
import ImgJpx from 'components/landingpage/assets/jpx.svg'
import ImgPrivacymark from 'components/landingpage/assets/privacymark.png'
import { useSelector } from 'react-redux'
import logo2 from 'components/landingpage/assets/logo2.svg'
import { getDoorCustomize } from 'modules/door'
import { RootState } from 'store'

export default function FooterView() {
  const doorCustomize = useSelector((state: RootState) =>
    getDoorCustomize(state)
  )

  return (
    <footer
      className={
        doorCustomize
          ? `${styles['footer']} ${styles['-customize']}`
          : `${styles['footer']}`
      }
    >
      <div
        className={
          doorCustomize
            ? `${styles['footer-inner']} ${styles['-customize']}`
            : `${styles['footer-inner']}`
        }
      >
        {doorCustomize ? (
          <>
            <ul className={`${styles['footer-list']} ${styles['-customize']}`}>
              {[
                { text: 'よくある質問', href: '/faq' },
                { text: 'お問い合わせ', href: '/faq' },
                { text: '運営会社', href: 'https://www.fancs.com/' },
                { text: '利用規約', href: '/terms' },
                { text: 'ガイドライン', href: '/guideline' },
                { text: '個人情報の取り扱いについて(YOOR)', href: '/privacy' },
                { text: '特定商取引法に関する表記', href: '/law' }
              ].map((link) => {
                return (
                  <li
                    key={link.text}
                    className={`${styles['footer-list-item']} ${styles['-customize']}`}
                  >
                    <ul
                      className={`${styles['footer-link-list']} ${styles['-customize']}`}
                    >
                      <li className={`${styles['footer-link-lits-item']}`}>
                        <a
                          href={link.href}
                          target="_blank"
                          className={`${styles['footer-link']}`}
                        >
                          {link.text}
                        </a>
                      </li>
                    </ul>
                  </li>
                )
              })}
            </ul>
            <small
              className={`${styles['footer-copyright']} ${styles['-customize']}`}
            >
              Copyright © 2024 FAN Communications Inc. All Rights Reserved.
            </small>
          </>
        ) : (
          <>
            <div className={`${styles['footer-logo-box']}`}>
              <Link to={'/'} className={`${styles['footer-logo']}`}>
                <img
                  src={logo2}
                  alt="YOOR"
                  className={`${styles['footer-logo-image']}`}
                />
              </Link>
            </div>
            <ul className={`${styles['footer-list']}`}>
              <li className={`${styles['footer-list-item']}`}>
                <h3 className={`${styles['footer-link-title']}`}>
                  サロンを作る
                </h3>
                <ul className={`${styles['footer-link-list']}`}>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <Link to="/signup" className={`${styles['footer-link']}`}>
                      サロンを開設する
                    </Link>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <Link
                      to="/opensalon"
                      className={`${styles['footer-link']}`}
                    >
                      サロン開設について
                    </Link>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <Link to="/beginner" className={`${styles['footer-link']}`}>
                      YOORについて
                    </Link>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <Link to="/support" className={`${styles['footer-link']}`}>
                      サポートサービス
                    </Link>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <a
                      href="https://form.run/@yoor-document"
                      target="_blank"
                      className={`${styles['footer-link']}`}
                    >
                      資料請求
                    </a>
                  </li>
                </ul>
              </li>
              <li className={`${styles['footer-list-item']}`}>
                <h3 className={`${styles['footer-link-title']}`}>
                  サロンを探す
                </h3>
                <ul className={`${styles['footer-link-list']}`}>
                  {/* <li className={`${styles['footer-link-lits-item']}`}>
                <Link to="/pickup" className={`${styles['footer-link']}`}>
                  おすすめから探す
                </Link>
              </li> */}
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <Link to="/newsalon" className={`${styles['footer-link']}`}>
                      新着から探す
                    </Link>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <Link
                      to="/freesalon"
                      className={`${styles['footer-link']}`}
                    >
                      無料から探す
                    </Link>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <Link to="/keyword" className={`${styles['footer-link']}`}>
                      キーワードから探す
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`${styles['footer-list-item']}`}>
                <h3 className={`${styles['footer-link-title']}`}>ヘルプ</h3>
                <ul className={`${styles['footer-link-list']}`}>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <a
                      href="/faq"
                      target="_blank"
                      className={`${styles['footer-link']}`}
                    >
                      よくある質問
                    </a>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <a
                      href="/faq"
                      target="_blank"
                      className={`${styles['footer-link']}`}
                    >
                      お問い合わせ
                    </a>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <a
                      href="/guideline"
                      target="_blank"
                      className={`${styles['footer-link']}`}
                    >
                      ガイドライン
                    </a>
                  </li>
                </ul>
              </li>
              <li className={`${styles['footer-list-item']}`}>
                <h3 className={`${styles['footer-link-title']}`}>その他</h3>
                <ul className={`${styles['footer-link-list']}`}>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <a
                      href="/terms"
                      target="_blank"
                      className={`${styles['footer-link']}`}
                    >
                      利用規約
                    </a>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <a
                      href="https://www.fancs.com/privacy"
                      target="_blank"
                      className={`${styles['footer-link']}`}
                      rel="noopener noreferrer"
                    >
                      個人情報保護方針
                    </a>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <a
                      href="/privacy"
                      target="_blank"
                      className={`${styles['footer-link']}`}
                    >
                      個人情報の取り扱いについて(YOOR)
                    </a>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <a
                      href="/law"
                      target="_blank"
                      className={`${styles['footer-link']}`}
                    >
                      特定商取引法に関する表記
                    </a>
                  </li>
                  <li className={`${styles['footer-link-lits-item']}`}>
                    <a
                      href="https://www.fancs.com/"
                      target="_blank"
                      className={`${styles['footer-link']}`}
                    >
                      運営会社
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className={`${styles['footer-social-image-wrap']}`}>
              <div className={`${styles['footer-social-image-wrap-inner']}`}>
                <a
                  href="https://blog.yoor.jp"
                  target="_blank"
                  className={`${styles['footer-social-image-link']}`}
                >
                  <img
                    src={icon_salocil}
                    className={`${styles['footer-social-image']}`}
                    alt="salocil"
                  />
                </a>
                <a
                  href="https://x.com/yoor_jp"
                  target="_blank"
                  className={`${styles['footer-social-image-link']}`}
                >
                  <img
                    src={icon_twitter}
                    className={`${styles['footer-social-image']}`}
                    alt="twitter"
                  />
                </a>
              </div>
              <div className={`${styles['footer-certification-mark']}`}>
                <a
                  href="https://privacymark.jp/"
                  target="_blank"
                  className={`${styles['footer-certification-mark-item']}`}
                >
                  <img src={ImgPrivacymark} alt="privacymark" />
                  <p>
                    当社は、JIPDECより
                    <br />
                    プライバシーマーク付与事業者に
                    <br />
                    認定されています
                  </p>
                </a>
                <div className={`${styles['footer-certification-mark-item']}`}>
                  <img src={ImgJpx} alt="jpx" />
                  <p>
                    証券コード2461
                    <br />
                    <br />
                    株式会社ファンコミュニケーションズ
                  </p>
                </div>
              </div>
            </div>
            <small className={`${styles['footer-copyright']}`}>
              Copyright © 2024 FAN Communications Inc. All Rights Reserved.
            </small>
          </>
        )}
      </div>
    </footer>
  )
}
